<template>
  <div class="actions-popup" ref="actions">
    <div
      class="d-flex align-items-center justify-content-center"
      @click="toggleActionsPopup = !toggleActionsPopup"
      v-click-outside="hide"
    >
      <icon-actions />
    </div>
    <div
      v-if="toggleActionsPopup"
      class="actions-popup__popup"
      :style="{transform: `translateY(${yTransform}px`}"
      ref="dropdown"
    >
      <div
        v-for="item in actionsList"
        :key="item.id"
        v-html="item.label"
        class="actions-popup__list-item"
        @click="$emit('onAction', item.slug)"
      ></div>
    </div>
  </div>
</template>

<script>
import IconActions from "@/js/components/icons/IconActions";

export default {
  name: "ActionsPopup",
  components: { IconActions },
  props: {
    actionsList: Array,
    requestApproval: {
      type: Function
    },

    width: {
      type: String,
      default: '100px'
    },
    height: {
      type: String,
      default: '100px'
    },
    parentRef: Element
  },
  data() {
    return {
      toggleActionsPopup: false,
      yTransform: 0,
    };
  },
  methods: {
    hide(e) {
      if (this.$refs.actions.contains(e.target)) return;
      this.toggleActionsPopup = false;
    }
  },
  watch: {
    async toggleActionsPopup(next) {
      if (!next) return;
      await this.$nextTick();
      const { top, height } = this.$refs.dropdown.getBoundingClientRect();
      const parent = this.parentRef.getBoundingClientRect();
      const topFromParent = top - parent.top;
      const diff = this.parentRef.clientHeight - topFromParent - height;
      if (diff < 0) this.yTransform = diff - 20;
    }
  },
};
</script>

<style lang="scss" scoped>
.actions-popup {
  position: relative;

  &__popup {
    position: absolute;
    top: 0;
    right: 32px;
    min-width: 130px;
    margin: 0;
    padding: 0;
    background: var(--white-main);
    box-shadow: 0 2px 6px 2px rgba(0, 42, 81, 0.1);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    z-index: 2;
  }

  &__list-item {
    padding: 13px 12px;
    color: var(--black-main);

    &:hover {
      background-color: var(--blue-light);
    }
  }
}

</style>