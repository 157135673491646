<template>
  <div class="v-pagination mt-4">
    <div class="v-pagination__per-page">
      Rows per page:
      <VSelect
          :options="_perPageForSelect"
          :value="currentPerPage"
          get-type="object"
          size="sm"
          class="v-pagination__per-page-select"
          withoutIconCheck
          @input="onChangePerPage"
      />
    </div>
    <div class="v-pagination__total">
      {{ tablePag.fromPage || '0' }}-{{ tablePag.toPage || '0' }} of {{ tablePag.totalItems }}
    </div>
    <Paginate
        class="mb-0"
        :value="tablePag.currentPage"
        :initial-page="3"
        :page-count="tablePag.totalPages"
        :page-range="3"
        :margin-pages="2"
        :click-handler="onChangeCurrentPage"
        container-class="v-pagination__paginate ml-4"
        page-class="page-link page-link__custom"
        prev-class="page-link page-link__custom"
        next-class="page-link page-link__custom"
        :prev-text="`<span class='page-link__custom--prev'></span>`"
        :next-text="`<span class='page-link__custom--next'></span>`"
    />
  </div>
</template>

<script>
import VSelect from '@/js/components/common/VSelect';
import Paginate from 'vuejs-paginate';

export default {
  name: "VPagination",
  components: {
    VSelect,
    Paginate
  },
  props: {
    tablePag: Object,
    countPerPage: Array,
    currentPerPage: Number,
  },
  computed: {
    _perPageForSelect() {
      return this.countPerPage.map(perPage => ({
        id: perPage,
        label: perPage
      }));
    },
  },
  methods: {
    onChangePerPage(option) {
      this.$emit('onChangePerPage', +option.id);
    },
    onChangeCurrentPage(page) {
      this.$emit('onChangeCurrentPage', +page);
    }
  }
};
</script>

<style lang="scss">
.v-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__per-page {
    display: flex;
    align-items: center;

    &-select {
      margin-left: 12px;
    }
  }

  &__total {
    margin-left: 12px;
  }

  &__paginate {
    display: flex;
  }
}

.page-link {
  &__custom {
    margin: 0 2px;
    border-radius: 26px;

    a {
      --size: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--size);
      height: var(--size);
      color: var(--black-main);
    }

    &:hover {
      color: var(--black-main);
    }

    &.active {
      background-color: var(--blue-main);
      border-color: var(--blue-main);

      a {
        color: var(--white-main);
      }
    }

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: .5;
    }

    &--prev,
    &--next {
      display: block;
      width: 16px;
      height: 16px;
    }

    &--prev {
      background: center/20px no-repeat url(~@/assets/img/pagination/icon-pag-prev.svg);
    }

    &--next {
      background: center/20px no-repeat url(~@/assets/img/pagination/icon-pag-next.svg);
    }
  }
}
</style>
