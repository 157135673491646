<template>
  <svg class="icon-actions" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1664_41369" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_1664_41369)">
      <circle cx="12" cy="5" r="2" fill="#BCC1C8"/>
      <circle cx="12" cy="12" r="2" fill="#BCC1C8"/>
      <circle cx="12" cy="19" r="2" fill="#BCC1C8"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconActions"
}
</script>

<style scoped>
.icon-actions {
  cursor: pointer;
}

</style>