import _ from 'lodash';

export default {
  data: () => ({
    countPerPage: [10, 25, 50, 100],
    currentPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    defaultItems: [],
    allItems: [],
    items: [],
  }),
  computed: {
    _tablePag() {
      const defaultItemsLength = this.defaultItems.length;
      const lastElCurrentPage = this.currentPage * this.currentPerPage;
      const fromPage = (lastElCurrentPage - this.currentPerPage) + 1;
      const toPage = lastElCurrentPage < defaultItemsLength ? lastElCurrentPage : defaultItemsLength;

      return {
        currentPage: this.currentPage,
        fromPage,
        toPage,
        totalItems: defaultItemsLength,
        totalPages: this.totalPages,
      };
    }
  },
  methods: {
    setPagination(allItems) {
      this.defaultItems = allItems;
      this.allItems = _.chunk(allItems, this.currentPerPage);
      this.totalPages = _.size(this.allItems);
      this.items = this.allItems[this.currentPage - 1] || this.allItems[0] || [];
    },
    onChangeCurrentPage(page) {
      this.currentPage = page;
      this.items = this.allItems[page - 1] || this.allItems[0];
    },
    onChangePerPage(perPage) {
      this.currentPage = 1;
      this.currentPerPage = perPage;
      this.setPagination(this.defaultItems);
    }
  }
};