<template>
  <div class="v-table">
    <div class="v-table__wrap" ref="tableWrap">
      <table class="v-table__container">
        <thead class="v-table__head">
        <tr>
          <th
              v-for="head in headCols"
              :key="head.id"
              class="v-table__head-th"
          >
            <div
                class="v-table__head-th-text"
                :class="{'v-table__head-th-text--sort': head.sort}"
                @click="onSortByCol(head)"
            >
              {{ head.name }}
              <span
                  v-if="head.sort"
                  class="v-table__head-th-sort"
              >
                <v-icon icon="sort-default" v-if="head.orderBy === 'def'"/>
                <v-icon icon="sort-asc" v-if="head.orderBy === 'asc'"/>
                <v-icon icon="sort-desc" v-if="head.orderBy === 'desc'"/>
              </span>
            </div>
          </th>
        </tr>
        </thead>
        <tbody class="v-table__body">
        <tr v-if="!items.length">
          <td :colspan="headCols.length" class="text-center">
            No data to display
          </td>
        </tr>
        <tr
            v-else
            v-for="(row,index) in items"
            :key="row.id"
            class="tr-item"
        >
          <td
              v-for="(col) in headCols"
              :key="col.id"
          >
            <div class="td-item">
              <template>
                <slot
                    :name="`col-${col.id}`"
                    :count="index"
                    :row="row"
                >
                  {{ row[col.slug] }}
                </slot>
              </template>
            </div>
          </td>

          <td v-if="actionsIsActive">
            <ActionsPopup
                :actionsList="formattedActionsList(row)"
                :parent-ref="$refs.tableWrap"
                @onAction="$emit('onAction', $event, row)"
            />
          </td>
          <td v-else-if="optionsIsActive">
            <div class="v-table__body-options">
              <span @click="onRemoveRow(row)" class="v-table__body-options-item">
                <v-icon icon="delete" class="mr-3"/>
              </span>
              <span @click="onEditRow(row)" class="v-table__body-options-item">
                <v-icon icon="edit-row-table"/>
              </span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <VPagination
        v-if="defaultItems.length > 0"
        :table-pag="_tablePag"
        :count-per-page="countPerPage"
        :current-per-page="currentPerPage"
        @onChangePerPage="onChangePerPage"
        @onChangeCurrentPage="onChangeCurrentPage"
    />
  </div>
</template>

<script>
import ActionsPopup from "@/js/components/common/ActionsPopup";
import VPagination from '@/js/components/common/VPagination';
import PaginationMixin from '@/js/mixins/pagination';

export default {
  name: "VTable",
  components: {
    VPagination,
    ActionsPopup,
  },
  mixins: [
    PaginationMixin
  ],
  props: {
    headCols: Array,
    bodyRows: Array,
    actionsIsActive: Boolean,
    optionsIsActive: Boolean,
    actionsList: Array,
    requestApproval: Function,
  },
  mounted() {
    this.setPagination(this.bodyRows);
  },
  methods: {
    formattedActionsList(offer) {
      if (offer?.relationship?.offer_affiliate_status === 'approved' || offer?.relationship?.offer_affiliate_status === 'rejected') {
        return this.actionsList.filter(obj => obj.slug !== 'require_approval');
      }

      return this.actionsList;
    },
    onSortByCol(head) {
      const relation = {
        def: 'asc',
        asc: 'desc',
        desc: 'def'
      };

      this.$emit('onSortByCol', {
        id: head.id,
        orderBy: relation[head.orderBy],
        sort: head.slug,
      });
    },

    onRemoveRow(row) {
      this.$emit("onRemoveRow", row);
    },
    onEditRow(row) {
      this.$emit("onEditRow", row);
    },
  },
  watch: {
    bodyRows(bodyRows) {
      this.setPagination(bodyRows);
    }
  },
};
</script>

<style lang="scss" scoped>
.v-table {
  width: 100%;

  &__wrap {
    overflow-x: auto;
    width: 100%;
    border-radius: 8px 8px 0 0;
    border: 1px solid #ECF3FE;
  }

  &__container {
    width: 100%;
    border-collapse: collapse;
    color: var(--font-color);
    background-color: var(--white);
    font-size: 14px;
    white-space: nowrap;
  }

  &__head {
    box-shadow: 0px 2px 6px 2px rgba(0, 42, 81, 0.1);

    tr {
      border-bottom: 1px solid var(--border-color);

      th {
        position: relative;
        padding: 12px 8px 12px 16px;
        text-align: left;
        color: var(--black-main);
        font-weight: 600;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          display: block;
          width: 1px;
          height: 24px;
          background-color: #B7BAC5;
          transform: translateY(-50%);
        }

        &:last-child:after {
          display: none;
        }
      }
    }

    &-th-text {
      display: flex;
      align-items: center;

      &--sort {
        cursor: pointer;
      }
    }

    &-th-sort {
      display: flex;
      margin-left: 6px;
    }
  }

  &__body {
    tr {
      border-bottom: 1px solid var(--grey-light-1);
      position: relative;

      &:hover {
        background: var(--blue-light);

        .v-table__body-options {
          display: flex;
        }
      }

      td {
        padding: 16px 8px 16px 16px;
      }
    }

    .td-item {
      min-height: 20px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-wrap: break-word;
      word-break: break-word;
    }

    &-action-child {
      display: flex;
      align-items: center;
    }

    &-options {
      display: none;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 20px;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
    }

    &-options-item {
      cursor: pointer;

      &:hover {
        color: red;
      }
    }

    &-active-column {
      cursor: pointer;
      height: 48px;
    }

    &-parent-td {
      font-weight: bolder !important;
    }

    &-select {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>